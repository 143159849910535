<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>试卷评价</el-breadcrumb-item>
        <el-breadcrumb-item>评价列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="yt-container yt-container-flex" :class="{ 'is-pack-up': isPackUp }" style="height: 100%">
      <div class="yt-content-tree">
        <template v-if="isPackUp">
          <YTIcon class="pack-up-button" :href="'#icon-zhankai'" @click="isPackUp = false" />
        </template>
        <template v-else>
          <div class="header">
            <YTIcon class="pack-up-button" :href="'#icon-shouqi1'" @click="isPackUp = true" />
            <YTIcon style="margin: 0 5px 0 19px; font-size: 20px" :href="'#icon-fenlei1'" />
            <p>考试列表</p>
          </div>
          <div class="tree">
            <el-tree ref="examEvaList-tree" :data="examEvaList" node-key="examId" :highlight-current="true" @node-click="getExamEvaById">
              <div slot-scope="{ node, data }" class="tree-node">
                <el-tooltip effect="dark" :content="data.examName" :disabled="data.examName.length < 10" placement="top-start">
                  <div class="el-tree-node__label">{{ data.examName }}</div>
                </el-tooltip>
              </div>
            </el-tree>
          </div>
        </template>
      </div>
      <div class="yt-resize">
        ⋮
      </div>
      <div class="yt-content">
        <div class="nData" v-if="evaList.length === 0">暂无数据</div>
        <div class="data" v-else>
          <div class="listS" v-for="(eva, eIndex) in evaList" :key="`eva-${eIndex}`">
            <div style="width: 100%;justify-content: space-between;margin-top: 5px;margin-left: 5px;">
              <div>
                <el-avatar class="avatar" size="medium" :src="eva.avatar || image" />
                <div style="padding-left: 8px;flex-direction: column; margin-left: 3px">
                  <span style="font-weight: bold">{{ eva.creatorName }}</span>
                  <span style="color: #7e7f8091;margin-top: 5px">{{ eva.createTime | getTime }}</span>
                </div>
              </div>
              <div>
                <el-button style="padding-top: 2px; padding-right: 10px" type="text" @click="delTooltip(eva)">删除</el-button>
              </div>
            </div>
            <div class="evaContent">
              <!--                <span style="white-space: nowrap">评论:</span><el-divider direction="vertical" />-->
              <span>{{ eva.content || '暂无评价' }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import util from '../../../../util/util'
import exam from '../../../../api/exam'
import image from '../../../../assets/defaultAvatar.svg'
export default {
  name: 'examEva',
  components: {
    YTIcon
  },
  data() {
    return {
      examNameSear: '',
      examEvaList: [],
      evaList: [],
      row: null,
      idIndex: 0,
      listQuery: {
        examName: '',
        pageSize: 10,
        pageNum: 0,
        total: 0
      },
      isPackUp: false,
      IsLoading: null,
      mulSeleList: [],
      treeClick: false
    }
  },
  filters: {
    getTime(date) {
      let d = new Date(date)
      return util.dateTime(d)
    }
  },
  created() {
    this.getList()
  },
  computed: {
    image: function() {
      return image
    }
  },
  mounted() {
    this.$dragDiv()
  },
  methods: {
    areaLoad(elementName) {
      this.IsLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        target: document.querySelector(`#${elementName}`)
      })
    },
    getList: function() {
      exam.getEvaluationList(this.listQuery).then(res => {
        this.examEvaList = res.res.data
        this.listQuery.total = res.res.total
        // console.log(this.examEvaList)
        if (this.examEvaList.length > 0) {
          let row = this.examEvaList.findIndex(i => i.examId === this.idIndex)
          if (row === -1) {
            this.idIndex = this.examEvaList[0].examId
          }
          this.getExamEvaById(this.idIndex)
        } else {
          this.evaList = []
        }
      })
    },
    getExamEvaById(id) {
      this.areaLoad('examEvaData')
      exam
        .getEvaByExamId(typeof id === 'number' ? id : id.examId)
        .then(res => {
          this.evaList = res.res
          this.IsLoading.close()
        })
        .catch(() => {
          this.IsLoading.close()
        })
    },
    handleSizeChange(size) {
      this.listQuery.pageNum = 0
      this.listQuery.pageSize = size
      this.getList()
    },
    handleCurrentChange(num) {
      this.listQuery.pageNum = num - 1
      this.getList()
    },
    handleSelect(data) {
      this.mulSeleList = data
    },
    delTooltip(index) {
      this.$confirm('确定删除选中的评论吗?', '提示', {
        confirmButtonText: '',
        cancelButtonText: '',
        type: 'warning'
      }).then(() => {
        this.del(index)
      })
    },
    del(row) {
      let form = {
        evaluationId: []
      }
      form.evaluationId.push(row.id)
      exam.examEvaDel(form).then(res => {
        if (res.res) {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../theme/evaluation';
@import '../../../../theme/dragDiv';
.data {
  .wh();
  overflow-y: auto;
  padding: 5px 5px 0 5px;
  div {
    display: flex;
    justify-content: flex-start;
  }
  span {
    text-align: left;
  }
  flex-direction: column;

  .listS {
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &:not(:first-child) {
      margin-top: 5px;
    }
    &:nth-child(2n + 1) {
      background-color: white;
    }
    &:nth-child(2n) {
      background-color: #f8f8f9;
    }

    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background-color: white;
    }

    .evaContent {
      .font();
      align-items: center;
      span {
        padding: 0 28px 0 45px;
        word-break: break-word;
      }
    }
  }
}
</style>
